<template>
  <div>
    <el-dialog
      title="挂单列表"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="form" :inline="true">
        <el-form-item label="挂单人">
          <el-input placeholder="请输入挂单人"></el-input>
        </el-form-item>
        <el-form-item label="挂单时间">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              align="center">
              <template slot-scope="scope">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handlePickUp(scope.row.order_number)">提取</el-button>
            </template>
          </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import { mountOrderListReq } from '@/api/order/offlineOrder/list';

export default {
  data() {
    return {
      loading: false,
      form: {},
      tableData: [],
      rowList: [
        { field_text: '订单号', field_alias: 'order_number', t_width: 250 },
        { field_text: '销售金额', field_alias: 'amount' },
        { field_text: '挂单时间', field_alias: 'create_time' },
        { field_text: '挂单人', field_alias: 'store_user_name' },
      ],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.mountOrderList();
  },
  methods: {
    // 提取订单
    handlePickUp(order_number) {
      this.$emit('onHidePickUp', order_number);
    },
    // 挂单列表
    mountOrderList() {
      this.loading = true;
      mountOrderListReq()
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.forEach((item) => {
              item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
              item.amount = numeral(item.amount).format('0.00');
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit('onHidePickUp');
    },
  },
};
</script>
